import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { SEO } from '../components';
import Layout from '../page-components/products/ProductListLayout';

const Products = ({ data, pageContext, location: { pathname } }) => {
  //
  const {
    queriedProducts: { edges: products },
    queriedMdx: { edges: mdx },
  } = data;

  const merged = products.map(product => ({
    ...product.node,
    ...mdx.find(
      ({
        node: {
          fields: { slug },
        },
      }) => slug === product.node.fields.slug
    ),
  }));

  return (
    <article>
      <SEO title="All products" description="All products" />
      <Layout products={merged} productTypes={pageContext.productTypes} />
    </article>
  );
};

export default Products;

Products.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query($limit: Int!, $skip: Int!) {
    queriedProducts: allShopifyProduct(sort: { fields: title, order: ASC }, limit: $limit, skip: $skip) {
      edges {
        ...ShopifyProducts
      }
    }
    queriedMdx: allMdx(filter: { frontmatter: { sourceType: { eq: "Products" } } }) {
      edges {
        ...AllMdx
      }
    }
  }
`;
